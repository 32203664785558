<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div class="d-flex justify-content-start">
            <b-avatar :src="items.logo" size="70px" />
            <div class="d-flex flex-column ml-2">
              <div>
                <h3>
                  {{ items.name }}
                </h3>
                <span style="font-size: 15px">
                  <feather-icon
                    size="25"
                    icon="UsersIcon"
                    style="margin-bottom: 5px"
                  />
                  {{ items.fetchURL.followers }}
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                  {{ items.basicId }}
                </span>
              </div>
            </div>
          </div>
        </b-card>
        <b-row>
          <b-col cols="6">
            <b-card>
              <h2><b>บรอดแคสต์</b></h2>
              ส่งข้อความเพื่อสื่อสารกับเพื่อนของบัญชีทางการได้เลย<br /><br />
              <b-button
                variant="primary"
                size="sm"
                :to="{ path: 'BroadcastMessage/add' }"
              >
                สร้างข้อความใหม่
              </b-button>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card>
              <h2><b>ล็อคอินแคมเปญ</b></h2>
              สร้างคอนเทนต์เพื่อสื่อสารกับผู้ใช้งาน Wi-Fi ได้เลย<br /><br />
              <b-button
                variant="outline-primary"
                size="sm"
                :to="{ path: '/BrandPages' }"
              >
                สร้างคอนเทนต์
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-card>
          <broadcast />
        </b-card>
        <b-card>
          <users />
        </b-card>
        <!-- <b-card>5</b-card> -->
      </b-col>
      <b-col cols="3" style="display: none">
        <b-card>
          <span style="font-size: 15px">
            <feather-icon
              size="25"
              icon="EditIcon"
              style="margin-bottom: 5px"
            />
            โปรดตั้งค่า Splash Page ของคุณเพื่อให้ลูกค้าได้รับข่าวสารของคุณ
          </span>
        </b-card>
        <!-- <b-card>7</b-card>-->

        <b-card>
          <div class="blog-categories">
            <h6 class="mb-1">ประกาศ</h6>

            <div
              v-for="item in announce_news"
              :key="item.id"
              class="d-flex justify-content-start align-items-center mb-75"
            >
              <a :href="item.link" target="_blank">
                <b-avatar
                  rounded
                  size="32"
                  :variant="item.variant"
                  class="mr-75"
                >
                  <feather-icon :icon="item.icon" size="16" />
                </b-avatar>
              </a>

              <span>
                <a :href="item.link" target="_blank">
                  <div class="blog-category-title text-body">
                    {{ item.text }}
                  </div>
                </a>
              </span>
            </div>
          </div>
        </b-card>
        <b-card no-body>
          <b-carousel id="carousel-caption" controls indicators>
            <b-carousel-slide
              v-for="item in announce_news1"
              :key="item.id"
              class="card-img-overlay"
              :text="item.text"
              :img-src="item.pictrue"
            />
          </b-carousel>
        </b-card>
        <b-card>
          <div class="blog-categories">
            <h6 class="mb-1">ช่วยเหลือ</h6>

            <div class="d-flex justify-content-start align-items-center mb-75">
              <b-link>
                <b-avatar
                  rounded
                  size="32"
                  :variant="tagsColor('Fashion')"
                  class="mr-75"
                >
                  <feather-icon icon="AlignLeftIcon" size="16" />
                </b-avatar>
              </b-link>
              <b-link>
                <div class="blog-category-title text-body">คู่มือการใช้งาน</div>
              </b-link>
            </div>
            <div class="d-flex justify-content-start align-items-center mb-75">
              <b-link>
                <b-avatar
                  rounded
                  size="32"
                  variant="light-warning"
                  class="mr-75"
                >
                  <feather-icon icon="TwitchIcon" size="16" />
                </b-avatar>
              </b-link>
              <b-link>
                <div class="blog-category-title text-body">
                  คำคสามที่พบบ่อย && ติดต่อสอบถาม
                </div>
              </b-link>
            </div>
            <div class="d-flex justify-content-start align-items-center mb-75">
              <b-link>
                <b-avatar rounded size="32" variant="light-info" class="mr-75">
                  <feather-icon icon="CheckSquareIcon" size="16" />
                </b-avatar>
              </b-link>
              <b-link>
                <div class="blog-category-title text-body">สิ่งที่ต้องทำ</div>
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BImg,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatarGroup,
  BMediaBody,
  BLink,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import broadcast from "./broadcast.vue";
import users from "./users.vue";

import { packages_check } from "@/auth/utils";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatarGroup,
    BMediaBody,
    BLink,
    BCarousel,
    BCarouselSlide,
    broadcast,
    users,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      items: {
        name: "กำลังโหลดดด",
        basicId: "กำลังโหลดดด",
        fetchURL: { followers: 0 },
      },
      announce_news: [],
      announce_news1: [],
      socialShareIcons: [
        "GithubIcon",
        "GitlabIcon",
        "FacebookIcon",
        "TwitterIcon",
        "LinkedinIcon",
      ],
    };
  },
  created() {
    console.log('packages_check utils:>> ', packages_check);
    if (localStorage.getItem("oneSelect") == 1) {
      localStorage.setItem("oneSelect", 2);
      window.location.reload();
    }
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("raning_index", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.items = response.data.lineoa;
          this.announce_news = response.data.announce_news;
          this.announce_news1 = response.data.announce_news1;
          console.log("this.items :>> ", this.items);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-blog.scss";
</style>
